import { GetLpFormDataQuery } from 'generated/graphql';
import { ClientTypeEnum } from 'generated/graphql';
import { parseDateFromStringUsingTimezone } from 'technical/date';

import { LpProfile } from './types';

export const getProfileFormValues = (
  lpProfile: NonNullable<GetLpFormDataQuery['lp']>,
): LpProfile => {
  if (!lpProfile) {
    return {
      client: ClientTypeEnum.LegalEntity,
      country: '',
      managementCompanyId: '',
      uniqueCompanyIdentifier: '',
      registrationLocation: '',
      subscribingEntityName: '',
      logoFilePath: '',
      logoDocumentId: '',
      address: {
        streetLine: '',
        streetLine2: '',
        city: '',
        zipCode: '',
      },
      individualLegalRepresentative: {
        jobTitle: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber1: '',
      },
    };
  }

  const isIndividual = lpProfile.client === ClientTypeEnum.Individual;

  const lpIndividualGeneralInfos = lpProfile.lpContacts?.[0];
  const contactData = lpIndividualGeneralInfos?.contactIndividualEntity;

  const address = isIndividual
    ? contactData?.address
    : lpProfile?.lpLegalEntityGeneralInfos?.[0]?.address;

  return {
    // Need the "as" because client is a string in DB, not an enum
    client: lpProfile.client as ClientTypeEnum,
    logoFilePath: '',
    logoDocumentId: lpProfile.logoDocumentId ?? '',
    country:
      lpProfile.client === ClientTypeEnum.Individual
        ? contactData?.address?.country
        : lpProfile.lpLegalEntityGeneralInfos?.[0]?.country,
    managementCompanyId: lpProfile.managementCompanyId ?? '',
    uniqueCompanyIdentifier:
      lpProfile.lpLegalEntityGeneralInfos?.[0]?.uniqueCompanyIdentifier ?? '',
    registrationLocation:
      lpProfile.lpLegalEntityGeneralInfos?.[0]?.registrationLocation ?? '',
    subscribingEntityName:
      lpProfile.client === ClientTypeEnum.Individual
        ? [
            contactData?.title,
            contactData?.firstName,
            contactData?.lastName,
          ].join(' ')
        : lpProfile.lpLegalEntityGeneralInfos?.[0]?.subscribingEntityName,
    legalEntityIdentifier:
      lpProfile.lpLegalEntityGeneralInfos?.[0]?.legalEntityIdentifier ?? '',
    address: {
      streetLine: address?.streetLine ?? '',
      streetLine2: address?.streetLine2 ?? '',
      city: address?.city ?? '',
      zipCode: address?.zipCode ?? '',
      country: address?.country ?? '',
    },
    individualLegalRepresentative: {
      jobTitle: contactData?.jobTitle ?? '',
      email: contactData?.email ?? '',
      firstName: contactData?.firstName ?? '',
      lastName: contactData?.lastName ?? '',
      phoneNumber1: contactData?.phoneNumber1 ?? '',
      title: contactData?.title ?? '',
      taxpayerIdentificationNumber:
        contactData?.taxpayerIdentificationNumber ?? '',
      birthDate: contactData?.birthDate
        ? parseDateFromStringUsingTimezone(contactData.birthDate)
        : undefined,
      birthCity: contactData?.birthCity ?? '',
      birthDepartment: contactData?.birthDepartment ?? '',
      birthCountry: contactData?.birthCountry ?? '',
      hasOperationalRights: lpIndividualGeneralInfos?.hasOperationalRights,
      canAccessAmpereReports: lpIndividualGeneralInfos?.canAccessAmpereReports,
      canAccessCapitalAccountStatement:
        lpIndividualGeneralInfos?.canAccessCapitalAccountStatement,
      canAccessESGReports: lpIndividualGeneralInfos?.canAccessESGReports,
      canAccessGeneralReports:
        lpIndividualGeneralInfos?.canAccessGeneralReports,
      canAttendInvestorsMeeting:
        lpIndividualGeneralInfos?.canAttendInvestorsMeeting,
    },
  };
};
