import { GetFundBankDetailsQuery } from 'generated/graphql';

import { FundBankDetails } from './types';

export const getBankFormValues = (
  bankDetails?: NonNullable<GetFundBankDetailsQuery['fundBankDetails'][0]>,
): FundBankDetails => {
  if (bankDetails) {
    return {
      name: bankDetails.name,
      bic: bankDetails.bic,
      iban: bankDetails.iban,
      contact: bankDetails.contact,
      comment: bankDetails.comment,
      accountHolder: bankDetails.accountHolder,
      accountNumber: bankDetails.accountNumber,
      address: bankDetails.address ?? undefined,
    };
  }

  return {
    name: '',
    bic: '',
    iban: '',
    contact: '',
    address: undefined,
  };
};
