import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  LegalEntityKycRole,
  UltimateBeneficialOwnerControlType,
} from 'generated/graphql';
import { FormDropdownCountry, FormInputText, SelectInput } from 'ui/form';

export const UltimateEffectiveControlInput = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const roles = watch('roles');

  if (!roles.includes(LegalEntityKycRole.UltimateBeneficialOwner)) {
    return null;
  }

  return (
    <>
      <FormInputText
        name="ultimateEffectiveControlPercentage"
        label={t('components.lpContactUltimateEffectiveControl.label')}
        type="number"
      />
      <FormDropdownCountry
        label={t(
          'components.lpContactUltimateEffectiveControl.taxResidenceCountry',
        )}
        name="taxResidenceCountry"
        fullWidth
      />
      <FormInputText
        name="taxIdentificationNumber"
        label={t(
          'components.lpContactUltimateEffectiveControl.taxIdentificationNumber',
        )}
      />
      <UBOControlTypeSubForm />
    </>
  );
};

const UBOControlTypeSubForm = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const displayUBOControlType = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_UBO_CONTROL_TYPE,
  );

  if (!displayUBOControlType) {
    return null;
  }

  const controlType = watch('controlType');

  const orderedControlTypes = [
    UltimateBeneficialOwnerControlType.Detention,
    UltimateBeneficialOwnerControlType.OtherMean,
    UltimateBeneficialOwnerControlType.Designation,
    UltimateBeneficialOwnerControlType.Vocation,
    UltimateBeneficialOwnerControlType.LegalRepresentative,
    UltimateBeneficialOwnerControlType.Other,
  ];

  const options = orderedControlTypes.map((type) => ({
    id: type,
    value: t(`components.lpContactUltimateEffectiveControl.choices.${type}`),
  }));

  return (
    <>
      <SelectInput
        name="controlType"
        label={t('components.lpContactUltimateEffectiveControl.controlType')}
        required={true}
        options={options}
        fullWidth
      />
      {controlType === UltimateBeneficialOwnerControlType.Other && (
        <FormInputText
          name="controlTypeOther"
          label={t(
            'components.lpContactUltimateEffectiveControl.controlTypeOther',
          )}
          required={true}
        />
      )}
    </>
  );
};
