import { Stack } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { getFieldName } from 'technical/get-field-name';
import { FormDropdownCountry, FormInputText } from 'ui/form';

type LpLegalEntityFormPartProps = {
  control?: Control<LpProfile>;
  saving?: boolean;
  name?: string;
};

export const LpLegalEntityFormPart = ({
  control,
  saving,
  name,
}: LpLegalEntityFormPartProps) => {
  const { t } = useTranslation();

  const { field: country } = useController({
    control,
    name: getFieldName('country', name) as 'country',
  });

  const handleCountryChange = (value: string | null) => {
    country.onChange(value);
  };

  const displayFormFieldLegalEntityIdentifier = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_FORM_FIELD_LEGAL_ENTITY_IDENTIFIER,
  );

  return (
    <>
      <FormDropdownCountry
        label={t('pages.fundManager.lpCreation.form.country.label')}
        name={getFieldName('country', name)}
        control={control}
        disabled={saving}
        fullWidth
        required
        onChange={handleCountryChange}
      />
      <Stack direction="row" spacing={1}>
        <FormInputText
          fullWidth
          name={getFieldName('uniqueCompanyIdentifier', name)}
          label={t(
            'pages.fundManager.lpCreation.form.uniqueCompanyIdentifier.label',
          )}
          control={control}
          required
          disabled={saving}
        />
        <FormInputText
          fullWidth
          name={getFieldName('registrationLocation', name)}
          label={t(
            'pages.fundManager.lpCreation.form.registrationLocation.label',
          )}
          control={control}
          required
          disabled={saving}
        />
      </Stack>
      <FormInputText
        name={getFieldName('subscribingEntityName', name)}
        label={t(
          'pages.fundManager.lpCreation.form.subscribingEntityName.label',
        )}
        control={control}
        required
        disabled={saving}
      />
      {displayFormFieldLegalEntityIdentifier && (
        <FormInputText
          name={getFieldName('legalEntityIdentifier', name)}
          label={t(
            'pages.fundManager.lpCreation.form.legalEntityIdentifier.label',
          )}
          control={control}
          disabled={saving}
        />
      )}
    </>
  );
};
