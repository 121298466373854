import { getLpContactRoles } from 'business/shared/lp-creation-module/services/get-lp-contact-roles';
import { UltimateBeneficialOwnerControlType } from 'generated/graphql';
import { getCountryNameByCountryCode } from 'technical/country-utils';
import {
  convertFrom10X8toNumber,
  formatRatioToPercentage,
} from 'technical/currency/formatters';
import { ValidationEntityTypeMultiple } from 'ui/validation-card';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { getContactItems } from './get-contact-items';
import { GetCardItemsParams, LpValidationLegalEntity } from './types';
import { getCommunicationTypesItem } from './utils';

export const getContactsList = ({
  data,
  t,
  language,
  withRoles = true,
}: Omit<
  GetCardItemsParams<LpValidationLegalEntity['lpContacts']>,
  'declineReasons'
> & { withRoles?: boolean }): ValidationEntityTypeMultiple => ({
  type: 'multiple',
  entities: data.map(({ contactIndividualEntity, ...rest }) => ({
    items: [
      ...getContactItems({ t, language, data: contactIndividualEntity }),
      ...(withRoles
        ? ([
            {
              type: VALIDATION_ITEM_TYPES.TEXT,
              label: t('components.lpContactRole.label'),
              value: getLpContactRoles(rest)
                .map((role) => t('components.lpContactRole', { context: role }))
                .join(', '),
            },
            ...(rest.isUltimateBeneficialOwner
              ? [
                  {
                    type: VALIDATION_ITEM_TYPES.TEXT,
                    label: t(
                      'components.lpContactUltimateEffectiveControl.label',
                    ),
                    value:
                      rest.ultimateEffectiveControlPercentage &&
                      formatRatioToPercentage(
                        convertFrom10X8toNumber(
                          rest.ultimateEffectiveControlPercentage,
                        ) / 100, // Div by 100 to get the percentage ratio
                      ),
                  },
                  {
                    type: VALIDATION_ITEM_TYPES.TEXT,
                    label: t(
                      'components.lpContactUltimateEffectiveControl.taxResidenceCountry',
                    ),
                    value:
                      rest.taxResidenceCountry &&
                      getCountryNameByCountryCode({
                        code: rest.taxResidenceCountry,
                        language,
                      }),
                  },
                  {
                    type: VALIDATION_ITEM_TYPES.TEXT,
                    label: t(
                      'components.lpContactUltimateEffectiveControl.taxIdentificationNumber',
                    ),
                    value: rest.taxIdentificationNumber,
                  },
                  ...(rest.controlType
                    ? [
                        {
                          type: VALIDATION_ITEM_TYPES.TEXT,
                          label: t(
                            'components.lpContactUltimateEffectiveControl.controlType',
                          ),
                          value: t(
                            `components.lpContactUltimateEffectiveControl.choices.${rest.controlType}`,
                          ),
                        },
                      ]
                    : []),
                  ...(rest.controlType ===
                    UltimateBeneficialOwnerControlType.Other &&
                  rest.controlTypeOther
                    ? [
                        {
                          type: VALIDATION_ITEM_TYPES.TEXT,
                          label: t(
                            'components.lpContactUltimateEffectiveControl.controlTypeOther',
                          ),
                          value: rest.controlTypeOther,
                        },
                      ]
                    : []),
                ]
              : []),
          ] as ValidationItemType[])
        : ([] as ValidationItemType[])),
      ...getCommunicationTypesItem({
        data: rest,
        t,
      }),
    ],
  })),
});
