import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OnboardingSubscriptionAmount } from 'business/lp-platform/onboarding-subscription/pages/onboarding-subscription-completion/onboarding-subscription-completion.types';
import { OnboardingSubscriptionFormInput } from 'business/lp-platform/onboarding-subscription/services/types';
import { onboardingSubscriptionFormInputSchema } from 'business/lp-platform/onboarding-subscription/services/validation';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  BankDetails,
  CurrencyEnum,
  OnboardingSubscriptionTypeEnum,
  useCompleteOnboardingSubscriptionMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';

import { FormOnboardingSubscriptionComplete } from './form-onboarding-subscription-complete';

type OnboardingSubscriptionCompletionFormProps =
  OnboardingSubscriptionAmount & {
    id: string;
    currency: CurrencyEnum;
    fundName: string;
    defaultBankDetails?: BankDetails;
    declineComment?: string;
  };
export const OnboardingSubscriptionCompletionForm = ({
  id,
  fundName,
  currency,
  defaultBankDetails,
  declineComment,
  ...rest
}: OnboardingSubscriptionCompletionFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<OnboardingSubscriptionFormInput>({
    defaultValues: {
      bankDetails: defaultBankDetails,
      amount:
        rest.type === OnboardingSubscriptionTypeEnum.Fixed
          ? rest.amount
          : rest.amountMin + Math.round((rest.amountMax - rest.amountMin) / 2),
    },
    resolver: yupResolver(onboardingSubscriptionFormInputSchema),
  });

  const [completeMutation] = useCompleteOnboardingSubscriptionMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      navigate(LpPlatformRoutes.Home);
    },
    onError: (err) => {
      enqueueSnackbar(t([err.message, ValidationErrors.GENERIC]), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const onSubmit = ({ amount, bankDetails }: OnboardingSubscriptionFormInput) =>
    completeMutation({
      variables: {
        input: {
          amount,
          id,
          bankDetails: {
            id: bankDetails.id,
            name: bankDetails.name,
            bic: bankDetails.bic,
            iban: bankDetails.iban,
            accountNumber: bankDetails.accountNumber,
            accountHolder: bankDetails.accountHolder,
            comment: bankDetails.comment,
            contact: bankDetails.contact,
            address: bankDetails.address && {
              id: bankDetails.address.id,
              name: bankDetails.address.name,
              streetLine: bankDetails.address?.streetLine,
              streetLine2: bankDetails.address?.streetLine2,
              city: bankDetails.address?.city,
              zipCode: bankDetails.address?.zipCode,
              country: bankDetails.address?.country,
            },
          },
        },
      },
    }).catch(() => undefined);

  return (
    <FormProvider {...methods}>
      <FormOnboardingSubscriptionComplete
        fundName={fundName}
        currency={currency}
        {...rest}
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={() => navigate(LpPlatformRoutes.Home)}
        isSubmitting={methods.formState.isSubmitting}
        declineComment={declineComment}
      />
    </FormProvider>
  );
};
