import { TFunction } from 'translations/hook';

import { BankDetails } from 'business/shared/services/types';
import { Address } from 'generated/graphql';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

const bankDetailsKeys: (keyof BankDetails | keyof Omit<Address, 'name'>)[] = [
  'name',
  'iban',
  'bic',
  'contact',
  'accountHolder',
  'accountNumber',
  'streetLine',
  'streetLine2',
  'city',
  'zipCode',
  'country',
];

export const getBankDetails = ({
  bankDetails,
  t,
}: {
  bankDetails?: BankDetails;
  t: TFunction;
}): ValidationItemType[] => {
  const flatBankDetails: any = {
    ...bankDetails,
    ...bankDetails?.address,
  };
  const bankDetailsValidations = bankDetailsKeys
    .map((key): ValidationItemType | undefined => {
      const value = flatBankDetails[key];
      if (!value) {
        return undefined;
      }

      return {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(`components.bankDetails.${key}`),
        value,
      };
    })
    .filter(
      (v: ValidationItemType | undefined): v is ValidationItemType => !!v,
    );
  return bankDetailsValidations;
};
