import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LanguageEnum } from 'translations/types';

import { useLanguageContext } from 'business/providers/language-provider';
import { Condition, LpCustomQuestionTypeEnum } from 'generated/graphql';
import { FormInputText, FormInputDropdown } from 'ui/form';

import { shouldDisplayFormPart } from './custom-form-part.utils';

type CustomQuestionText = {
  type: LpCustomQuestionTypeEnum.Text;
};

type CustomQuestionOption = {
  type:
    | LpCustomQuestionTypeEnum.Select
    | LpCustomQuestionTypeEnum.SelectMultiple;
  choices: {
    options: {
      value: string;
      label: Record<'fr' | 'en', string>;
    }[];
  };
};

type CustomFormInputProps = (CustomQuestionText | CustomQuestionOption) & {
  id: string;
  name: string;
  labelEn: string;
  labelFr: string;
  isRequired: boolean;
  condition: Condition | null;
};
export const CustomFormInput = ({
  isRequired,
  name,
  labelEn,
  labelFr,
  condition,
  ...rest
}: CustomFormInputProps) => {
  const { language } = useLanguageContext();

  const [shouldDisplay, setShouldDisplay] = useState(!condition);

  const { setValue } = useFormContext();
  const customAnswers = useWatch({ name: 'customAnswers' });

  useEffect(() => {
    setValue(`${name}.isRequired`, shouldDisplay && isRequired);
  }, [shouldDisplay, setValue, name, isRequired]);

  useEffect(() => {
    if (condition) {
      setShouldDisplay(shouldDisplayFormPart(condition, customAnswers));
    }
  }, [setShouldDisplay, condition, customAnswers]);

  if (!shouldDisplay) {
    return null;
  }

  const label = language === LanguageEnum.en ? labelEn : labelFr;

  if (rest.type === LpCustomQuestionTypeEnum.Text) {
    return (
      <FormInputText
        label={label}
        name={`${name}.value`}
        multiline
        required={isRequired}
        hasFloatingLabel={false}
      />
    );
  }

  const options = rest.choices.options.map((opt) => ({
    id: opt.value,
    value: opt.label[language],
  }));

  return (
    <FormInputDropdown
      label={label}
      name={
        rest.type === LpCustomQuestionTypeEnum.SelectMultiple
          ? `${name}.values`
          : `${name}.value`
      }
      options={options}
      fullWidth
      multiple={rest.type === LpCustomQuestionTypeEnum.SelectMultiple}
      required={isRequired}
      hasFloatingLabel={false}
    />
  );
};
