import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useController } from 'react-hook-form';

import { cn } from 'technical/tailwind/tailwind.utils';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FormInputsType, FormInputProps } from 'ui/form/form-input-props';

import styles from './index.module.scss';

export interface FormInputDropdownProps<
  T extends FormInputsType = FormInputsType,
> extends FormInputProps<T> {
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: SelectChangeEvent<string>) => void;
  className?: string;
  fullWidth?: boolean;
  minWidth?: number;
  multiple?: boolean;
  chip?: boolean;
  hasFloatingLabel?: boolean;
}
export const FormInputDropdown = <T extends FormInputsType>({
  name,
  control,
  label,
  rules,
  required,
  options = [],
  dataTestId,
  onChange,
  className,
  size,
  minWidth,
  fullWidth,
  readOnly,
  multiple,
  chip,
  hasFloatingLabel = true,
  ...rest
}: FormInputDropdownProps<T>) => {
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name: name as `value`,
    rules: { ...rules, required },
    control,
    defaultValue: multiple ? [] : '',
  });

  // Override MUI Select default render for selected values
  // input is "string[]" in case of multi-select otherwise "string"
  const renderValue = (value: string | string[]) => {
    if (value instanceof Array) {
      const values = value.map(
        (answer) => options.find(({ id }) => id === answer)?.value ?? answer,
      );

      if (chip) {
        return (
          <div className="flex flex-wrap gap-1">
            {values.map((v) => (
              <Chip key={v} label={v} />
            ))}
          </div>
        );
      }

      return values.map((v) => `• ${v}`).join('\n');
    }
    return options.find(({ id }) => id === value)?.value ?? value;
  };

  return (
    <>
      {label && !hasFloatingLabel && (
        <InputLabel
          required={required}
          id="input-label"
          className="whitespace-break-spaces"
        >
          {label}
        </InputLabel>
      )}
      <FormControl
        className={cn(className, !hasFloatingLabel && '!mt-2')}
        required={required}
        error={!!fieldError}
        size={size}
        fullWidth={fullWidth}
        sx={{ minWidth }}
        data-test-id={`select-${name}`}
      >
        {label && hasFloatingLabel && (
          <InputLabel id="input-label" className="whitespace-break-spaces">
            {label}
          </InputLabel>
        )}
        <Select
          {...rest}
          name={name}
          labelId="input-label"
          value={field.value}
          onChange={onChange ?? field.onChange}
          inputRef={field.ref}
          data-test-id={dataTestId}
          readOnly={readOnly}
          multiple={multiple}
          MenuProps={{
            classes: {
              paper: styles.paper,
              list: styles.list,
            },
          }}
          renderValue={renderValue}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              data-test-id={`${option.id}`}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        {fieldError ? (
          <ErrorLabel label={fieldError.message ?? ValidationErrors.GENERIC} />
        ) : null}
      </FormControl>
    </>
  );
};
