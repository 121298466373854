import { TFunction } from 'translations/hook';
import * as yup from 'yup';

import { bankDetailsSchema } from 'business/shared/services/validation';
import {
  CurrencyEnum,
  FundFromDateEnum,
  FundReportingTypeEnum,
  FundStageEnum,
} from 'generated/graphql';
import { uploadedFileSchema } from 'technical/file-management/validation';
import {
  noFutureDateRequiredSchema,
  uuidOptionnalSchema,
  uuidRequiredSchema,
  nonEmptyStringSchema,
  nonNegativeNumberSchema,
} from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

import { EMPTY_MANAGER_REGULATOR, managerRegulators } from './constants';
import { computeResidualValue } from './get-residual-value-in-share-valuation';

export const fromDateSchema = yup
  .mixed<FundFromDateEnum>()
  .oneOf(Object.values(FundFromDateEnum));

export const currencySchema = yup
  .mixed<CurrencyEnum>()
  .oneOf(Object.values(CurrencyEnum));

export const stageSchema = yup
  .mixed<FundStageEnum>()
  // hack of yup because standard mixed solution not seems to works fine with unrequired fields
  .test((value) => !value || Object.values(FundStageEnum).includes(value))
  .transform((value) => (value ? value : undefined));

export const fundProfileSchema = yup.object({
  // term part
  termDelay: yup.number().min(0).required('errors.required'),
  termFromDateOf: fromDateSchema.required('errors.required'),
  termExtension: yup.number().min(0),
  // general information part
  name: nonEmptyStringSchema,
  firstClosingDate: yup.date().required('errors.required'),
  finalClosingDate: yup
    .date()
    // here we need to chain condition because all in one not working :'(
    .when('termFromDateOf', {
      is: (value: FundFromDateEnum) =>
        value === FundFromDateEnum.FinalClosingDate,
      then: (schema) => schema.required('errors.required'),
    })
    .when('investmentPeriodFromDateOf', {
      is: (value: FundFromDateEnum) =>
        value === FundFromDateEnum.FinalClosingDate,
      then: (schema) => schema.required('errors.required'),
    }),
  vintageYear: yup.number().required('errors.required'),
  firstInvestmentDate: yup
    .date()
    // here we need to chain condition because all in one not working :'(
    .when('termFromDateOf', {
      is: (value: FundFromDateEnum) =>
        value === FundFromDateEnum.FirstInvestmentDate,
      then: (schema) => schema.required(),
    })
    .when('investmentPeriodFromDateOf', {
      is: (value: FundFromDateEnum) =>
        value === FundFromDateEnum.FirstInvestmentDate,
      then: (schema) => schema.required(),
    }),
  currency: yup
    .mixed<CurrencyEnum>()
    .oneOf(Object.values(CurrencyEnum))
    .required('errors.required'),
  valuationPolicy: yup.string(),
  // investment focus
  stage: stageSchema,
  sector: yup.string(),
  geography: yup.string(),
  // investment period
  duration: yup.number().min(0),
  investmentPeriodFromDateOf: fromDateSchema,
  investmentPeriodExtension: yup.number().min(0),
  // key economic
  managementFees: yup.string(),
  feeOffsets: yup.string(),
  carriedInterest: yup.string(),
  // other
  managerRegulator: yup
    .string()
    .oneOf([...managerRegulators, EMPTY_MANAGER_REGULATOR]),
  auditor: yup.string(),
  outlineOfStructure: yup.string(),
  managementCompanyId: nonEmptyStringSchema,
});

export const fundBankDetailsSchema = bankDetailsSchema;

// TODO need to refacto this schema to change it into standard schema and not a function
export const getShareTransferFormSchema = ({
  commitment,
  dateOfLastOperation,
  t,
}: {
  commitment: number;
  dateOfLastOperation?: string;
  t: TFunction;
}) =>
  yup.object({
    fundId: uuidRequiredSchema,
    lpBuyerId: nonEmptyStringSchema
      .test(
        'isDifferentThanSeller',
        t('errors.shareTransferCreation_invalid-buyer'),
        (value, context: { parent: { lpSellerId: string } }) => {
          return context.parent.lpSellerId !== value;
        },
      )
      .required(ValidationErrors.REQUIRED),
    lpSellerId: nonEmptyStringSchema,
    shareId: nonEmptyStringSchema,
    date: dateOfLastOperation
      ? yup
          .date()
          .min(
            dateOfLastOperation,
            t('errors.too_short_date', {
              date: new Date(dateOfLastOperation).toLocaleDateString(),
            }),
          )
          .required(ValidationErrors.REQUIRED)
      : yup.date().required(ValidationErrors.REQUIRED),
    amount: yup
      .number()
      .min(1, t('errors.too_short_number', { number: 1 }))
      .max(commitment, t('errors.shareTransferCreation_amount-too-big'))
      .required(ValidationErrors.REQUIRED),
    numberOfShares: yup
      .number()
      .min(1, t('errors.too_short_number', { number: 1 }))
      .required(t('errors.required')),
    totalPrice: yup.number().defined(),
    bankDetails: bankDetailsSchema.required(ValidationErrors.REQUIRED),
    files: yup.array().of(uploadedFileSchema).optional().default([]),
  });

export const deprecatedCreateFundShareValuationSchema = yup.object({
  fundId: nonEmptyStringSchema,
  shareId: nonEmptyStringSchema,
  date: yup.date().required('errors.required'),
  residualValue: yup.number().min(0).required('errors.required'),
  paidIn: yup.number().min(0).required('errors.required'),
  distributedNonRecallable: yup.number().min(0).required('errors.required'),
  distributedRecallable: yup.number().min(0).required('errors.required'),
});

export const residualValueComputationSchema = yup.object({
  managementFees: nonNegativeNumberSchema,
  partnershipExpenses: nonNegativeNumberSchema,
  totalOffsetsToFeesExpenses: nonNegativeNumberSchema,
  interestIncome: nonNegativeNumberSchema,
  dividendIncome: nonNegativeNumberSchema,
  interestExpense: nonNegativeNumberSchema,
  otherIncomeExpense: nonNegativeNumberSchema,
  accruedCarriedInterest: nonNegativeNumberSchema,
  realizedPortfolio: yup.number(),
  unrealizedPortfolio: yup.number(),
  totalDrawn: nonNegativeNumberSchema,
  totalDistributedRecallable: nonNegativeNumberSchema,
  totalDistributedNonRecallable: nonNegativeNumberSchema,
});
const shareValuationSchema = residualValueComputationSchema.concat(
  yup.object({
    id: uuidOptionnalSchema,
    shareId: uuidRequiredSchema,
    lastFundShareValuationId: uuidOptionnalSchema,
    selected: yup.boolean(),
  }),
);

export const fundShareValuationComputationSchema = yup.object({
  fundId: uuidRequiredSchema,
  date: noFutureDateRequiredSchema,
  snapshotedAt: noFutureDateRequiredSchema,
  shareValuations: yup
    .array()
    .of(
      shareValuationSchema
        .when({
          is: (value: { selected?: boolean }) => !value.selected,
          then: () => yup.object({ selected: yup.boolean() }),
        })
        // Residual values cannot be included in the form, as other form values are used to compute the residual value (endless render loop)
        .test(
          'residual-value-non-negative',
          'errors.noNegativeResidualValue',
          function (value) {
            const newResidualValue = computeResidualValue(value);
            return newResidualValue >= 0;
          },
        ),
    )
    .required()
    .min(1)
    .test(
      'at-least-one-share-selected',
      'errors.atLeastOneShareSelected',
      (value) => (value ? value.some((item) => item.selected) : false),
    ),
});

export const fundReportingCreationSchema = yup.object({
  fundId: uuidRequiredSchema,
  type: yup.string().oneOf(Object.values(FundReportingTypeEnum)).required(),
  shareIds: yup
    .array()
    .of(uuidRequiredSchema)
    .when('type', {
      is: FundReportingTypeEnum.CapitalAccountStatement,
      then: (schema) => schema.min(1, 'errors.required'),
    }),
  valuationDate: yup.date().when('type', {
    is: FundReportingTypeEnum.CapitalAccountStatement,
    then: (schema) => schema.required(),
  }),
  subject: nonEmptyStringSchema,
  content: nonEmptyStringSchema,
  files: yup.array().of(uploadedFileSchema).required().max(1).default([]),
});
