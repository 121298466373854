import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { AddressFormPart } from 'business/shared/components/address-form-part';
import { getFieldName } from 'technical/get-field-name';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormSection } from 'ui/form';

type Props = {
  control: Control<any>;
  name?: string;
  error?: Error;
  action?: ReactNode;
  isDepositary?: boolean;
  isMandatoryBankDetailsInfo?: boolean;
};

export const BankDetailsAndAddressFormPart = ({
  action,
  control,
  error,
  name,
  isDepositary = false,
  isMandatoryBankDetailsInfo = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {error?.message ? <ErrorLabel label={error.message} /> : null}

      <FormSection title={t('components.bankDetails.title')} action={action}>
        <FormInputText
          label={t('components.bankDetails.name')}
          name={getFieldName('name', name)}
          control={control}
          required={!isDepositary}
        />
        <Stack direction="row" spacing={1}>
          <FormInputText
            label={t('components.bankDetails.iban')}
            name={getFieldName('iban', name)}
            control={control}
            required={!isDepositary}
            fullWidth
          />
          <FormInputText
            label={t('components.bankDetails.bic')}
            name={getFieldName('bic', name)}
            control={control}
            required={!isDepositary}
            fullWidth
          />
          <FormInputText
            label={t('components.bankDetails.accountNumber')}
            name={getFieldName('accountNumber', name)}
            control={control}
            required={isMandatoryBankDetailsInfo}
            fullWidth
          />
        </Stack>
        <FormInputText
          label={t('components.bankDetails.accountHolder')}
          name={getFieldName('accountHolder', name)}
          control={control}
          required={isMandatoryBankDetailsInfo}
        />
      </FormSection>

      <FormSection
        title={t(
          isDepositary
            ? 'components.bankDetails.depositaryTitle'
            : 'components.bankDetails.investorTitle',
        )}
        lastOne
      >
        <AddressFormPart
          control={control}
          name={getFieldName('address', name)}
          required={isMandatoryBankDetailsInfo}
        />

        <FormInputText
          label={t('components.bankDetails.contact')}
          multiline
          name={getFieldName('contact', name)}
          placeholder={t('components.bankDetails.contactPlaceholder')}
          control={control}
          required={isMandatoryBankDetailsInfo}
        />
      </FormSection>
    </>
  );
};
