import { isValidIBAN, isValidBIC, countrySpecs } from 'ibantools';
import * as yup from 'yup';

import {
  nonEmptyStringSchema,
  uuidOptionnalSchema,
} from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';
export const zipCodeValidationSchema = yup.string().trim();

// strange but we need to be sure there is rules to be applied
const isValidCountryCode = (countryCode: string) =>
  Object.keys(countrySpecs?.[countryCode] ?? {}).length > 0;

export const ibanSchema = yup
  .string()
  .uppercase()
  .trim()
  .test({
    name: 'is-valid-iban',
    message: ValidationErrors.INVALID,
    exclusive: true,
    test: (value) => {
      if (!value) {
        return true;
      }
      const cleanValue = value.replaceAll(/\s+/g, '');

      // pick up from ibantools source
      const countryCode = cleanValue.toUpperCase().slice(0, 2);
      if (isValidCountryCode(countryCode)) {
        return isValidIBAN(cleanValue);
      }

      return true;
    },
  });

export const bicSchema = yup
  .string()
  .uppercase()
  .trim()
  .test({
    name: 'is-valid-bic',
    message: ValidationErrors.INVALID,
    exclusive: true,
    test: (value) => {
      if (!value) {
        return true;
      }
      const cleanValue = value.replaceAll(/\s+/g, '');

      return isValidBIC(cleanValue);
    },
  });

export const addressRequiredSchema = yup.object({
  name: yup.string(),
  streetLine: nonEmptyStringSchema,
  streetLine2: yup.string(),
  city: nonEmptyStringSchema,
  // only string for now
  zipCode: zipCodeValidationSchema.required(),
  country: nonEmptyStringSchema,
});

export const addressSchema = yup.object().shape({
  streetLine: yup.string(),
  streetLine2: yup.string(),
  city: yup.string(),
  zipCode: zipCodeValidationSchema,
  country: yup.string(),
});

const unusedIfEmptyStringSchema = yup
  .string()
  .trim()
  .transform((value) =>
    value && value.trim() !== '' ? value.trim() : undefined,
  )
  .optional()
  .nullable();

const createDependentStringSchema = (dependencies: string[]) =>
  yup
    .string()
    .transform((value) =>
      value && value.trim() !== '' ? value.trim() : undefined,
    )
    .test({
      name: 'dependentFields',
      message: ValidationErrors.REQUIRED,
      test: function (value, context) {
        // Access the full object from the context
        const fullAddress = context?.from?.[0]?.value;
        // Check if any dependent field is filled
        const isAnyFieldFilled = dependencies.some(
          (dep) => fullAddress[dep] && fullAddress[dep].trim() !== '',
        );
        // If any field is filled, and current field is not filled, return false
        if (isAnyFieldFilled && !value) {
          return false;
        } else {
          return true;
        }
      },
    });

const optionalAddressSchema = yup
  .object()
  .shape({
    name: unusedIfEmptyStringSchema,
    streetLine2: unusedIfEmptyStringSchema,
    streetLine: createDependentStringSchema([
      'city',
      'zipCode',
      'country',
      'streetLine2',
    ]),
    city: createDependentStringSchema([
      'streetLine',
      'zipCode',
      'country',
      'streetLine2',
    ]),
    zipCode: createDependentStringSchema([
      'streetLine',
      'city',
      'country',
      'streetLine2',
    ]),
    country: createDependentStringSchema([
      'streetLine',
      'city',
      'zipCode',
      'streetLine2',
    ]),
  })
  .optional();

export const bankDetailsSchema = yup.object({
  name: nonEmptyStringSchema,
  bic: bicSchema.required(ValidationErrors.REQUIRED),
  iban: ibanSchema.required(ValidationErrors.REQUIRED),
  accountNumber: unusedIfEmptyStringSchema,
  accountHolder: unusedIfEmptyStringSchema,
  comment: unusedIfEmptyStringSchema,
  contact: unusedIfEmptyStringSchema,
  address: optionalAddressSchema.nullable(),
});

export const upsertBankDetailsSchema = yup.object({
  id: uuidOptionnalSchema,
  name: nonEmptyStringSchema,
  bic: bicSchema.required(ValidationErrors.REQUIRED),
  iban: ibanSchema.required(ValidationErrors.REQUIRED),
  accountNumber: unusedIfEmptyStringSchema,
  accountHolder: unusedIfEmptyStringSchema,
  comment: unusedIfEmptyStringSchema,
  contact: unusedIfEmptyStringSchema,
  address: optionalAddressSchema
    .concat(
      yup.object({
        id: uuidOptionnalSchema,
      }),
    )
    .nullable(),
});
