import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { BankDetailsAndAddressFormPart } from 'business/shared/components/bank-details-and-address-form-part';
import { BankDetails } from 'business/shared/services/types';
import { bankDetailsSchema } from 'business/shared/services/validation';
import {
  BankDetailsQuery,
  useBankDetailsQuery,
  useUpdateBankDetailsMutation,
} from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';

interface FormBankDetailsProps {
  bankDetails: BankDetails & { id: string };
  onClose: () => void;
}

const parseBankDetailsResult = (
  bankDetails: NonNullable<BankDetailsQuery['bankDetails']>,
): BankDetails & { id: string } => ({
  id: bankDetails.id,
  name: bankDetails.name,
  bic: bankDetails.bic,
  iban: bankDetails.iban,
  accountHolder: bankDetails.accountHolder ?? undefined,
  accountNumber: bankDetails.accountNumber ?? undefined,
  comment: bankDetails.comment ?? undefined,
  contact: bankDetails.contact ?? undefined,
  address: {
    name: bankDetails.address?.name ?? undefined,
    streetLine: bankDetails.address?.streetLine ?? undefined,
    streetLine2: bankDetails.address?.streetLine2 ?? undefined,
    city: bankDetails.address?.city ?? undefined,
    zipCode: bankDetails.address?.zipCode ?? undefined,
    country: bankDetails.address?.country ?? undefined,
  },
});

const FormBankDetails = ({
  onClose,
  bankDetails: { id, ...defaultValues },
}: FormBankDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const methods = useForm<BankDetails>({
    defaultValues,
    resolver: yupResolver<BankDetails>(bankDetailsSchema),
  });
  const { control } = methods;
  const [updateBankDetails, { error }] = useUpdateBankDetailsMutation({
    onCompleted: () => {
      onClose();
      enqueueSnackbar(t('successMessage.updateBankDetails'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const onSubmit = (input: BankDetails) => {
    updateBankDetails({
      variables: {
        input: {
          ...input,
          id,
        },
      },
      // for react-hook-form isSubmitting props
    }).catch(() => undefined);
  };

  return (
    <FormProvider {...methods}>
      <FormModalContainer
        title={t('pages.fundManager.lp.bankDetails.title')}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {error ? <ErrorLabel label={error.message} /> : null}
        <BankDetailsAndAddressFormPart control={control} />
      </FormModalContainer>
    </FormProvider>
  );
};

interface ModalFormBankDetailsProps {
  id: string;
  onClose: () => void;
}

export const ModalFormBankDetails = ({
  id,
  onClose,
}: ModalFormBankDetailsProps) => {
  const { t } = useTranslation();
  const { loading, data, error } = useBankDetailsQuery({ variables: { id } });

  if (loading || error || data === undefined) {
    return (
      <FormModalContainer
        title={t('pages.fundManager.lp.bankDetails.title')}
        onClose={onClose}
      >
        <QueryStateDisplay loading={loading} error={error} />
      </FormModalContainer>
    );
  }

  return (
    <FormBankDetails
      bankDetails={parseBankDetailsResult(data.bankDetails)}
      onClose={onClose}
    />
  );
};
