import { ApolloError } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useCreateFundBankDetailsMutation,
  useGetFundBankDetailsQuery,
  useUpdateFundBankDetailsMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';

import { getBankFormValues } from './get-bank-form-values';
import { FundBankDetails } from './types';

type UseBankFormProps = { fundId: string; onCompleted?: () => void };

type UseBankFormReturnQuery = { loading?: boolean; error?: ApolloError };
type UseBankFormReturnForm = {
  saving?: boolean;
  saveError?: ApolloError;
  onSubmit: (args: FundBankDetails) => void;
  defaultValues: FundBankDetails;
};

type UseBankFormReturn = UseBankFormReturnQuery | UseBankFormReturnForm;

export const isBankFormQuery = (
  args: UseBankFormReturn,
): args is UseBankFormReturnQuery => 'loading' in args || 'error' in args;

export const useBankForm = ({
  fundId,
  onCompleted,
}: UseBankFormProps): UseBankFormReturn => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetFundBankDetailsQuery({
    variables: { id: fundId },
    // in current url we don't have bankDetailsId
    // so we have to request a list a bankDetails
    // But like first list is empty, even if cache is well updated we have to relaunch it to make list up to date
    // TODO possible solution: add bankDetails id in url
    // fetchPolicy: 'network-only',
  });

  const [createBankDetails, creationResult] = useCreateFundBankDetailsMutation({
    onCompleted: () => {
      refetch();
      onCompleted?.();
      enqueueSnackbar(t('successMessage.updateBankDetails'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [updateMutation, editionResult] = useUpdateFundBankDetailsMutation({
    onCompleted: () => {
      refetch();
      onCompleted?.();
      enqueueSnackbar(t('successMessage.updateBankDetails'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  if (loading || error) {
    return { loading, error };
  }

  if (!data?.fundBankDetails?.[0]) {
    const onCreate = ({ address, ...input }: FundBankDetails) =>
      createBankDetails({
        variables: {
          input: {
            ...input,
            fundId,
            address: address ?? undefined,
          },
        },
      });

    return {
      onSubmit: onCreate,
      saving: creationResult.loading,
      saveError: creationResult.error,
      defaultValues: getBankFormValues(),
    };
  }

  const bankDetails = data.fundBankDetails[0];
  const onUpdate = ({ address, ...input }: FundBankDetails) =>
    updateMutation({
      variables: {
        input: {
          id: bankDetails.id,
          data: {
            fundId,
            ...input,
            address: address ?? undefined,
          },
        },
      },
    });

  return {
    onSubmit: onUpdate,
    saving: editionResult.loading,
    saveError: editionResult.error,
    defaultValues: getBankFormValues(bankDetails),
  };
};
