import { isFuture, isToday } from 'date-fns';
import * as yup from 'yup';

import {
  addressSchema,
  zipCodeValidationSchema,
} from 'business/shared/services/validation';
import { phoneNumberSchema } from 'business/user/validations/string';
import {
  ClientTypeEnum,
  ContactTypeEnum,
  LegalEntityKycRole,
  LpCustomQuestionTypeEnum,
  LpInvestorTypeEnum,
  UltimateBeneficialOwnerControlType,
} from 'generated/graphql';
import {
  emailRequiredSchema,
  emailSchema,
  floatNumberSchema,
  nonEmptyStringSchema,
  uuidRequiredSchema,
} from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

export const validateRequiredFieldDependingOnClientType = (
  clientType: ClientTypeEnum,
) => {
  return yup.string().when('client', {
    is: (type: ClientTypeEnum) => type === clientType,
    then: () => nonEmptyStringSchema,
    otherwise: (schema) => schema.optional().default(undefined),
  });
};

export const clientTypeEnumSchema = yup
  .mixed<ClientTypeEnum>()
  .oneOf(Object.values(ClientTypeEnum))
  .required();

export const customQuestionOptionSchema = yup.object({
  type: yup
    .mixed<LpCustomQuestionTypeEnum>()
    .oneOf([
      LpCustomQuestionTypeEnum.Select,
      LpCustomQuestionTypeEnum.SelectMultiple,
    ])
    .required(),
  choices: yup
    .object({
      options: yup
        .array()
        .defined()
        .of(
          yup.object({
            label: yup.object({
              fr: nonEmptyStringSchema,
              en: nonEmptyStringSchema,
            }),
            value: nonEmptyStringSchema,
          }),
        ),
    })
    .notRequired(),
});

export const customQuestionSchema = yup.mixed().oneOf([
  yup.object({
    type: yup
      .mixed<LpCustomQuestionTypeEnum>()
      .oneOf([LpCustomQuestionTypeEnum.Text])
      .required(),
  }),
  customQuestionOptionSchema,
]);

export const customAnswerSchema = yup.object({
  questionId: uuidRequiredSchema,

  isRequired: yup.boolean().defined(),

  type: yup
    .mixed<LpCustomQuestionTypeEnum>()
    .oneOf(Object.values(LpCustomQuestionTypeEnum))
    .defined(),

  value: yup.string().when(['isRequired', 'type'], {
    is: (isRequired: boolean, type: LpCustomQuestionTypeEnum) =>
      isRequired &&
      [LpCustomQuestionTypeEnum.Text, LpCustomQuestionTypeEnum.Select].includes(
        type,
      ),
    then: (schema) => schema.required(ValidationErrors.REQUIRED),
  }),

  values: yup
    .array()
    .of(nonEmptyStringSchema)
    .when(['isRequired', 'type'], {
      is: (isRequired: boolean, type: LpCustomQuestionTypeEnum) =>
        isRequired && type === LpCustomQuestionTypeEnum.SelectMultiple,
      then: (schema) =>
        schema
          .min(1, ValidationErrors.REQUIRED)
          .required(ValidationErrors.REQUIRED),
    }),
});

export const customAnswersSchema = yup.object({
  customAnswers: yup.array().of(customAnswerSchema),
});

export const getLpProfileSchema = ({
  isMobileRequired,
  isTaxpayerIdentificationNumberRequired,
  isBirthDateRequired,
  isBirthCountryRequired,
}: {
  isMobileRequired: boolean;
  isTaxpayerIdentificationNumberRequired: boolean;
  isBirthDateRequired: boolean;
  isBirthCountryRequired: boolean;
}) =>
  yup
    .object({
      isAutocomplete: yup.boolean(),
      managementCompanyId: uuidRequiredSchema,
      client: clientTypeEnumSchema,
      country: validateRequiredFieldDependingOnClientType(
        ClientTypeEnum.LegalEntity,
      ),
      uniqueCompanyIdentifier: validateRequiredFieldDependingOnClientType(
        ClientTypeEnum.LegalEntity,
      ),
      registrationLocation: validateRequiredFieldDependingOnClientType(
        ClientTypeEnum.LegalEntity,
      ),
      subscribingEntityName: validateRequiredFieldDependingOnClientType(
        ClientTypeEnum.LegalEntity,
      ),
      legalEntityIdentifier: leiSchema,

      address: yup
        .object({
          streetLine: nonEmptyStringSchema,
          streetLine2: yup.string(),
          city: nonEmptyStringSchema,
          zipCode: zipCodeValidationSchema.required(),
          country: yup.string(),
        })
        .when('client', {
          is: (type: ClientTypeEnum) => type === ClientTypeEnum.Individual,
          then: (schema) =>
            schema.concat(yup.object({ country: nonEmptyStringSchema })),
        }),
      logoFilePath: yup.string(),
      // since I'm changing the document's filepath to the permanent one
      // directly in the back, on draft update I get the document's ID
      logoDocumentId: yup.string(),
      // well I had to duplicate this code because of multiple problems
      // using the contactSchema with a when condition
      // we can spend some time on it later
      individualLegalRepresentative: yup
        .object()
        .shape({
          title: yup.string(),
          firstName: validateRequiredFieldDependingOnClientType(
            ClientTypeEnum.Individual,
          ),
          lastName: validateRequiredFieldDependingOnClientType(
            ClientTypeEnum.Individual,
          ),
          phoneNumber1: isMobileRequired
            ? phoneNumberSchema.required()
            : phoneNumberSchema,
          email: emailSchema,
          jobTitle: validateRequiredFieldDependingOnClientType(
            ClientTypeEnum.Individual,
          ),
          comment: yup.string(),
          taxpayerIdentificationNumber: isTaxpayerIdentificationNumberRequired
            ? nonEmptyStringSchema
            : yup.string(),
          birthDate: isBirthDateRequired
            ? yup.date().required()
            : yup.date().nullable(),
          birthCity: yup.string(),
          birthDepartment: yup.string(),
          birthCountry: isBirthCountryRequired
            ? nonEmptyStringSchema
            : yup.string(),
          hasOperationalRights: yup.boolean(),
          canAccessGeneralReports: yup.boolean(),
          canAttendInvestorsMeeting: yup.boolean(),
          canAccessESGReports: yup.boolean(),
          canAccessAmpereReports: yup.boolean(),
          canAccessCapitalAccountStatement: yup.boolean(),
        })
        .when('client', {
          is: (type: ClientTypeEnum) => type !== ClientTypeEnum.Individual,
          then: () => yup.object({}),
        }),
    })
    .concat(customAnswersSchema);

export const lpIndividualKYCSchema = yup
  .object({
    investorType: yup
      .mixed<LpInvestorTypeEnum>()
      .oneOf(Object.values(LpInvestorTypeEnum))
      .required(),
    taxPayerIdentificationNumber: nonEmptyStringSchema,
    investorGroup: yup.string(),
    professionnalClient: yup.boolean(),
    USRelatedPerson: yup.boolean(),
    frenchEquitySavingsPlan: yup.boolean(),
    taxOption: yup.boolean(),
    ampereReporting: yup.boolean(),
  })
  .concat(customAnswersSchema);

export const leiSchema = yup
  .string()
  .test('is-valid-lei', 'invalid-lei', (value) => {
    if (!value) {
      //LEI is Optional
      return true;
    }
    if (value.length !== 20) {
      return false;
    }
    const regex = /^[A-Za-z0-9]{4}[A-Za-z0-9]{14}[0-9]{2}$/;
    if (value && !regex.test(value)) {
      return false;
    }

    return true;
    // Ideally, we should also check the last 2 digits following the ISO 17442 standard.
    // Possible inspiration: https://github.com/EDumdum/lei-js
  });

export const lpKYBSchema = yup
  .object()
  .shape({
    legalForm: yup.string(),
    investorType: yup
      .mixed<LpInvestorTypeEnum>()
      .oneOf(Object.values(LpInvestorTypeEnum))
      .required(),
    professionnalClient: yup.boolean(),
    USRelatedPerson: yup.boolean(),
    frenchEquitySavingsPlan: yup.boolean(),
    taxOption: yup.boolean(),
    ampereReporting: yup.boolean(),
    lpNaceCode: yup.string(),
    corporatePurpose: yup.string(),
    headOfficeUniqueCompanyIdentifier: yup.string(),
    headOfficeNaceCode: yup.string(),
    headOfficeHeadcount: yup.string(),
    vatIdentificationNumber: yup.string(),
    investorGroup: yup.string(),
    endOfFiscalYear: yup
      .date()
      .nullable()
      .test('isFuture', 'errors.date_in_past', (value) => {
        if (!value) {
          return true;
        }
        return isToday(value) || isFuture(value);
      }),
    headcount: yup.string(),
    companyCapital: yup
      .number()
      // allows empty string as a value and transforms it as undefined,
      // it happens when we delete the number
      .transform((val, orig) => (orig === '' ? undefined : val)),
    currency: yup.string(),
  })
  .concat(customAnswersSchema);

export const contactLegalEntitySchema = yup.object({
  country: nonEmptyStringSchema,
  uniqueCompanyIdentifier: nonEmptyStringSchema,
  registrationLocation: nonEmptyStringSchema,
  subscribingEntityName: nonEmptyStringSchema,
});

export const ContactTypeEnumSchema = yup
  .mixed<ContactTypeEnum>()
  .oneOf(Object.values(ContactTypeEnum));

export const individualContactSchema = yup.object({
  title: yup.string(),
  firstName: nonEmptyStringSchema,
  lastName: nonEmptyStringSchema,
  phoneNumber1: phoneNumberSchema,
  email: emailRequiredSchema,
  jobTitle: yup.string(),
  jobEndDate: yup.date(),
  comment: yup.string(),
  taxpayerIdentificationNumber: yup.string(),
  birthDate: yup.date(),
  birthCity: yup.string(),
  birthDepartment: yup.string(),
  birthCountry: yup.string(),
  hasOperationalRights: yup.boolean(),
  canAccessGeneralReports: yup.boolean(),
  canAttendInvestorsMeeting: yup.boolean(),
  canAccessESGReports: yup.boolean(),
  canAccessAmpereReports: yup.boolean(),
  canAccessCapitalAccountStatement: yup.boolean(),
  type: ContactTypeEnumSchema.required(),
  legalEntity: contactLegalEntitySchema.when('type', {
    is: (type: ClientTypeEnum) => type === ClientTypeEnum.LegalEntity,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional().default(undefined),
  }),
});

export const lpContactSchema = yup.object().shape({
  contact: individualContactSchema,
  address: addressSchema,
});

const LegalEntityKYCContactRole = yup
  .mixed<LegalEntityKycRole>()
  .oneOf(Object.values(LegalEntityKycRole))
  .required(ValidationErrors.REQUIRED);

export const legalEntityKYCContactSchema = yup.object({
  address: addressSchema,
  contact: individualContactSchema
    .omit(['type', 'legalEntity'])
    .when('type', {
      is: (type: ContactTypeEnum) => type === ContactTypeEnum.LegalEntity,
      then: (schema) =>
        schema.concat(
          yup.object({
            legalEntity: contactLegalEntitySchema.required(),
          }),
        ),
    })
    .required(),
  type: ContactTypeEnumSchema.required(),
  ultimateEffectiveControlPercentage: floatNumberSchema
    .min(0, ValidationErrors.TOO_SHORT)
    .max(100, ValidationErrors.TOO_LONG)
    .nullable(),
  taxResidenceCountry: yup.string(),
  taxIdentificationNumber: yup.string(),
  controlType: yup
    .mixed<UltimateBeneficialOwnerControlType>()
    .oneOf(Object.values(UltimateBeneficialOwnerControlType)),
  controlTypeOther: yup.string(),
  roles: yup
    .array(LegalEntityKYCContactRole)
    .required(ValidationErrors.REQUIRED)
    .test(
      'contact-roles-validation',
      'errors.kyc-roles-forbidden',
      (roles, context: { parent: { type: ContactTypeEnum } }) => {
        return (
          context.parent.type !== ContactTypeEnum.LegalEntity ||
          roles.length === 0 ||
          !roles.some((role) =>
            [
              LegalEntityKycRole.LegalRepresentative,
              LegalEntityKycRole.UltimateBeneficialOwner,
            ].includes(role),
          )
        );
      },
    ),
});
